.firebase-emulator-warning {
  display: none;
}

.ant-rate-star-active .ant-rate-star-first svg, .ant-rate-star-full svg, .ant-rate-star-first svg {
  @apply fill-primary;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply sm:px-4 sm:py-2 md:px-4 md:py-2 font-semibold bg-primary rounded text-white max-w-[516px] cursor-pointer focus:bg-lightOrange hover:bg-lightOrange border-none text-sm min-w-28 disabled:bg-lightOrange disabled:cursor-not-allowed;
  }
}

@import "./tw-components.css"
