@font-face {
  font-family: Lark;
  src: url('./assets/Larke\ Neue\ Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'gelica-light';
  src: url('./assets/fonts/gelica-light-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://p.typekit.net/p.css?s=1&k=tyg0rtr&ht=tk&f=139.173.175&a=82634174&app=typekit&e=css");

@font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?subset_id=2&fvd=n7&v=3") format("opentype");
  font-display:swap;
  font-style:normal;
  font-weight:700;
  font-stretch:normal;
}

@font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
  font-display:swap;
  font-style:normal;
  font-weight:600;
  font-stretch:normal;
}

@font-face {
  font-family:"proxima-nova";
  src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:swap;
  font-style:normal;
  font-weight:400;
  font-stretch:normal;
}

@font-face {
  font-family: Cera;
  src: url('./assets/Cera\ Pro\ Medium.otf');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: Cera;
  src: url('./assets/Cera\ Pro\ Bold.otf');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

