.card {
  @apply flex flex-col min-w-0 break-words bg-white bg-clip-border rounded shadow-custom p-6;
}

.card-paleOrange {
  @apply min-w-0 break-words bg-clip-border rounded-lg shadow-custom p-4 bg-paleOrange;
}

.btn {
  @apply sm:px-4 sm:py-2 md:px-4 md:py-2 font-semibold bg-white rounded max-w-[516px] cursor-pointer focus:bg-gray-200 hover:bg-gray-200 text-sm min-w-28 border border-gray-200 border-solid disabled:bg-gray-200 disabled:cursor-not-allowed;
}

.btn-lg {
  @apply min-w-48;
}

.btn-dashed {
  @apply border-2 border-dashed border-primary hover:bg-paleOrange focus:bg-paleOrange text-primary;
}

.btn-danger {
  @apply bg-red-600 hover:bg-red-300 text-white;
}

.btn-sm {
  @apply min-w-12
}

.disabled {
  @apply pointer-events-none opacity-50 cursor-not-allowed;
}

.input {
  @apply rounded border border-gray-300 focus:outline-none focus:border-primary hover:border-primary border-solid transition disabled:bg-gray-200;
}

.input-lg {
  @apply input py-2 px-4 w-full;
}

.input-error {
  @apply border border-red-500;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  @apply border border-solid border-gray-200 rounded;
}

.checkbox-checked label:before {
  content: '\2714';
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 25px;
  @apply bg-primary;
}

.timepill {
  @apply flex justify-center items-center border border-gray-400 w-24 h-6 rounded-full text-sm leading-3 cursor-pointer text-center;
}

.title {
  @apply text-black sm:text-lg md:text-2xl leading-snug tracking-normal block;
}

.heading {
  @apply py-4 sm:text-2xl md:text-3xl font-bold
}

.section-heading {
  @apply truncate text-black font-semibold whitespace-nowrap overflow-hidden pl-0 uppercase tracking-widest;
}

span, p {
  @apply text-black font-primary leading-tight tracking-normal block
}

/* For some reason @mui/x-date-pickers CalendarPicker has visible css text from it's embedded <style> elements, we need this to hide that or it shows during page load  */
style {
  opacity: 0;
}

.select {
  @apply border-gray-200 border rounded border-solid bg-transparent py-2 px-4 hover:border-primary focus:border-primary focus:outline-none;
}

.font-cera {
  @apply font-[cera];
}

.navbar-container {
  @apply sm:px-6 md:px-9;
}

.ant-rate-star svg {
  @apply stroke-primary;
}

.text-light {
  @apply text-lightOrange;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-wrapper {
  overflow: hidden;
}

.skeleton-base {
  @apply bg-paleOrange rounded mb-1 relative overflow-hidden;
  animation: pulse 1.5s ease-in-out infinite;
}

.skeleton-base::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.2) 50%, transparent 100%);
  background-size: 1000px 100%;
}

.skeleton-sm {
  @apply skeleton-base min-w-8 w-2/5 min-h-6;
}

.skeleton-md {
  @apply skeleton-base w-3/5 min-h-6;
}

.skeleton-lg {
  @apply skeleton-base w-full min-h-6;
}

.skeleton-xl {
  @apply skeleton-base w-full min-h-10;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.error {
  @apply text-red-500;
}

.link {
  @apply text-primary hover:text-lightOrange active:hover:text-lightOrange cursor-pointer;
}

.tag {
  @apply py-1 px-3 rounded font-bold text-white;
}

.alert-base {
  @apply py-4 px-8 rounded;
}

.alert {
  @apply alert-base bg-primary text-white font-bold;
}

.alert-yellow {
  @apply alert-base bg-alertYellow;
}

.small {
  @apply block text-sm text-gray-500;
}

.pill {
  @apply px-2 py-1 font-bold rounded text-center border;
}

.pill-primary {
  @apply pill text-white bg-primary border-primary;
}

.separator {
  @apply border-t border-gray-300 border-solid my-4;
}
